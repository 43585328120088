@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 200;
    src: url(https://fonts.gstatic.com/s/mukta/v16/iJWHBXyXfDDVXbEOjGmc8WDm7Q.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
  }